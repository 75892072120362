import { Options } from "react-use-websocket";

export const SocketState = new Map([
  [-1, "UNINSTANTIATED"],
  [0, "CONNECTING"],
  [1, "OPEN"],
  [2, "CLOSING"],
  [3, "CLOSED"],
]);

export const SocketConfig: Options = {
  onOpen: () =>
    console.log(
      `%cWebSocket --> Connected [${new Date().toLocaleTimeString()}]`,
      "background: green; color: white; padding: 2px; border-radius: 4px; padding: 1px 30px;"
    ),
  shouldReconnect: (closeEvent) => true,
  reconnectInterval: 5000,
  onError: (err: any) => {
    console.log(
      `%cWebSocket --> onError err [${new Date().toLocaleTimeString()}]`,
      "background: black; color: yellow; padding: 2px; border-radius: 4px; padding: 1px 30px;",
      err
    );
  },
  onClose: (event) => {
    console.log(
      `%cWebSocket --> Close event [${new Date().toLocaleTimeString()}]`,
      "background: red; color: white; border-radius: 4px; padding: 1px 30px;",
      event
    );
  },
  onReconnectStop: (attemps) => {
    console.log(
      `%cWebSocket --> onReconnectStop attemps [${new Date().toLocaleTimeString()}]`,
      "background: cyan; color: black; padding: 2px; border-radius: 4px; padding: 1px 30px;",
      attemps
    );
  },
  heartbeat: false,
};
