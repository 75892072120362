import { useEffect, useRef, useState } from "react";
import { conf } from "../../utils/config";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useSelector } from "react-redux";
import { selectStudent, setStudentPrioridad, setToken } from "../../reduxSlices/studentSlice";
import {
  clearUnsubProcessSectionIds,
  selectSubcriptionProcessSectionIds,
  selectSubjectsQuotas,
  setDetailSubjectOffered,
  setLoadingRequestInTransit,
  removeSubProcessSectionIds,
  setFailedProcessingSubject,
  removeFailedProcessingSubject,
  ISubjectQuotas,
  deleteUnsubProcessSectionId,
} from "../../reduxSlices/subjectsSlice";

import { SocketConfig, SocketState } from "../../SocketConfig";
import { isJsonString } from "../../utils";
import { websocketTypes } from "../../enums/websocket.enum";
import {
  removeSelectedOfferedSubject,
  setInscribedSubjects,
  setOfferedSubjects,
  setSubjectsQuota,
} from "../../reduxSlices/subjectsSlice";
import { getCuposAlumno } from "../../Api/reservation";
import { useToastWarning, useToastError, useToastInfo, useToastSuccess } from "../../hooks/useToast";
import { getMateriasAlumno, getMateriasInscriptasAlumno } from "../../Api/subject";
import { useMsal } from "@azure/msal-react";
import { updateCupoCurso, updateCupoCursosList } from "../../reduxAsyncThunks/subjects";
import { useAppDispatch } from "../../app/store";
import {
  setInTransitInscriptionRequest,
  setLastUpdateOferta,
  setPanelFilters,
  setPanelSubjectInscribed,
} from "../../reduxSlices/globalFlagsSlice";
import { StorageKeys } from "../../utils/localStorage";
import { updatePrioridad } from "../../Api/prioridad";
import { dividirLista } from "../../utils/list";
import { v4 as uuid } from "uuid";

const previousHeartbeats: Record<string, number> = {};
interface SocketMsg {
  code: number;
  dataJson: DataJson;
  message: string;
  type: string;
}

export interface DataJson {
  alumnosProgramasIds?: number[];
  personaId: number;
  cursoId?: number;
  updateCupos?: boolean;
}

const checkIfQuotaExist = (subjectsQuotas: ISubjectQuotas, sectionId: number, quotaId: number) => {
  const sectionIds = Object.keys(subjectsQuotas);
  const idQuotas = Object.values(subjectsQuotas).map((s) => {
    return s.quotaId;
  });
  const existQuota = sectionIds.some((s) => Number(s) === sectionId) && idQuotas.includes(quotaId);
  return existQuota;
};

const getDeviceId = () => {
  const token = localStorage.getItem("token");
  if (token) {
    let deviceId = localStorage.getItem("UAID");
    if (!deviceId) {
      deviceId = uuid();
      localStorage.setItem("UAID", deviceId);
    }
    return deviceId;
  }else{
    localStorage.clear();
    return "X";
  }
};
export default function WebsocketManager() {
  const showError = useToastError();
  const showInfo = useToastInfo();
  const showSuccess = useToastSuccess();
  const showWarning = useToastWarning();
  const student = useSelector(selectStudent);
  const [idPersona, setIdPersona] = useState<null | number>(null);
  const dispatch = useAppDispatch();
  const subjectsQuotas = useSelector(selectSubjectsQuotas);
  const { instance } = useMsal();
  const redirectUri = process.env.REACT_APP_REDIRECT_URI;
  const subcriptionProcessSectionIds = useSelector(selectSubcriptionProcessSectionIds);

  const [ofertaEnCurso, setOfertaEnCurso] = useState(false);
  const [cambiosIgnoradoContados, setCambiosIgnoradoContados] = useState(0);

  const handleLogout = async () => {
    localStorage.clear();
    await dispatch(setToken(""));
    await instance.logoutRedirect({
      postLogoutRedirectUri: redirectUri,
    });
  };

  const handleCambioPrioridadAlumnos = async (socketMsg: SocketMsg) => {
    const idAlumnoPrograma = localStorage.getItem("idAP");
    console.log("handleCambioPrioridadAlumnos");
    if (idAlumnoPrograma && socketMsg.dataJson.alumnosProgramasIds?.includes(Number(idAlumnoPrograma))) {
      handleCambioPrioridad();
    }
  };

  const showWarningMessage = async (message: string) => {
    showWarning(message);
  };

  const handleCambioPrioridad = async () => {
    if (student.prioridad !== null) {
      const response = await updatePrioridad(student.prioridad);
      dispatch(
        setStudentPrioridad({ prioridad: response.tienePrioridad, mensajePrioridad: response.mensajePrioridad })
      );
    }
  };

  const heartbeatIntervalRef = useRef<number>();
  const socketHeartBeatUrl = conf("WSHB");
  const socketUrl = conf("WS");
  const ua = getDeviceId();

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    `${socketUrl}?idPersona=${idPersona}&ua=${ua}`,
    SocketConfig,
    !!student?.id && !!idPersona
  );

  useEffect(() => {
    if (student.id) {
      setIdPersona(student.id);
    }
  }, [student]);

  const handleRegenerarOferteEInscripciones = async (esOferta: boolean, socketMsg: SocketMsg) => {
    await dispatch(setInTransitInscriptionRequest(true)); // not use
    await dispatch(setDetailSubjectOffered(null)); // not use
    await dispatch(setPanelSubjectInscribed(false));
    await dispatch(setPanelFilters(false));
    const idAlumnoPrograma = localStorage.getItem("idAP");
    const debeActualizar =
      idAlumnoPrograma && socketMsg.dataJson.alumnosProgramasIds?.includes(Number(idAlumnoPrograma));
    /* ------------------------- regenerar inscripciones ------------------------ */
    if (!esOferta && debeActualizar) {
      const results = await getMateriasInscriptasAlumno(student.id);
      if (results.status === 200) {
        console.log("%cACTUALIZANDO MATERIAS INSCRIPTAS ⏳", "color: pink; text-decoration: underline;");
        await dispatch(setInscribedSubjects(results.data));
        console.log("%cMATERIAS INSCRIPTAS ACTUALIZADAS ✅", "color: green; text-decoration: underline;");
      }
      await dispatch(setLastUpdateOferta(new Date().getTime()));
    }
    /* ---------------------------- Regenerar oferta ---------------------------- */
    if (esOferta && debeActualizar) {
      const cursos = await getMateriasAlumno(String(student.id));
      console.log("cursos:", cursos);
      if (cursos && cursos.status === 200) {
        console.log("%cACTUALIZANDO OFERTA x ACTUALIZACION_OFERTA_KV ⏳", "color: cyan; text-decoration: underline;");
        await dispatch(setOfferedSubjects(cursos.data));
        console.log("%cOFERTA ACTUALIZADA ✅", "color: green; text-decoration: underline;");
      }
      await dispatch(setLastUpdateOferta(new Date().getTime()));
      /* ---------------------------- Actualiza cupos ---------------------------- */
      await updateCupos();
    }
    /* --------------------------------------------------------------------------- */
    // await dispatch(setInTransitInscriptionRequest(false)); //not use
  };

  const updateCupos = async () => {
    if (localStorage.getItem(StorageKeys.CupoCursosSynced) === "false") {
      console.log("%cACTUALIZANDO CUPOS ⏳", "color: cyan; text-decoration: underline;");
      dispatch(setPanelSubjectInscribed(false));
      dispatch(setPanelFilters(false));
      const results = await getCuposAlumno(student.id.toString());
      if (results && results.status === 200) {
        dispatch(setSubjectsQuota(results.data));

        if (results.response) {
          let promises: any[] = [];
          let cupoIds: number[] = [];
          const cuposRequestBatchSize = 25;
          for (const alumnoCupo of results.response) {
            if (alumnoCupo.cantidad !== -1 && alumnoCupo.idCupo !== -1) {
              cupoIds.push(alumnoCupo.idCupo);
            }
          }
          console.log(`Solicitando ${cupoIds.length} cupos`);
          let cupoIdsBatchs: number[][] = dividirLista(cupoIds, cuposRequestBatchSize);
          for (const cupoIdsBatch of cupoIdsBatchs) {
            promises.push(dispatch(updateCupoCursosList(cupoIdsBatch)));
            // await dispatch(updateCupoCursosList(cupoIdsBatch));
          }
          await Promise.all(promises);
          console.log("%cCUPOS ACTUALIZADOS✅", "color: green; text-decoration: underline;");
        }

        localStorage.setItem(StorageKeys.CupoCursosSynced, "true");
      }
    }
  };

  const handleRegenerarOferta = async (socketMsg: SocketMsg) => {
    /* ---------------------------- Regenerar oferta ---------------------------- */
    console.log("%cACTUALIZANDO OFERTA x CAMBIOS_EN_OFERTA ⏳", "color: cyan; text-decoration: underline;");
    const cursos = await getMateriasAlumno(String(student.id));
    if (cursos && cursos.status === 200) {
      await dispatch(setOfferedSubjects(cursos.data));
      console.log("%cOFERTA ACTUALIZADA ✅", "color: green; text-decoration: underline;");
    }
    if (socketMsg.dataJson.updateCupos === false) {
      console.log("Se omite update cupos");
    } else {
      localStorage.setItem(StorageKeys.CupoCursosSynced, "false");
      updateCupos();
    }
  };

  const handleCuposReload = async () => {
    localStorage.setItem(StorageKeys.CupoCursosSynced, "false");
  };

  /* -------------------------------------------------------------------------- */
  /*                                CONECTADO WS                                */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    console.log(
      `%cWebSocket state :>> ${SocketState.get(readyState)} [${new Date().toLocaleTimeString()}]`,
      "color: black; background: yellow; padding: 2px 30px;"
    );
    if (readyState === ReadyState.OPEN) {
      sendMessage("Connection established");
    }
  }, [readyState]);

  /* -------------------------------------------------------------------------- */
  /*                             READING WS Messages                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (lastMessage && isJsonString(lastMessage.data)) {
      const socketMsg: SocketMsg = JSON.parse(lastMessage.data);
      // console.log("EVENTO WS EN APP --->", socketMsg);
      console.log("EVENTO WS EN APP ---> Type: ", socketMsg.type);
      console.log(socketMsg);
      /* ---------------------------- IF socket NOT OK ---------------------------- */
      if (socketMsg.code !== 200 && socketMsg.dataJson) {
        const idPersona = socketMsg.dataJson.personaId;
        if (idPersona === student.id) {
          showError(socketMsg.message);
        }

        switch (socketMsg.type) {
          case websocketTypes.RESPUESTA_RESERVA:
            dispatch(setFailedProcessingSubject(socketMsg.dataJson.cursoId));
            setTimeout(() => {
              dispatch(removeFailedProcessingSubject(socketMsg.dataJson.cursoId));
            }, 3000);
            dispatch(removeSubProcessSectionIds(socketMsg.dataJson.cursoId));
            break;
          case websocketTypes.RESPUESTA_CONFIRMACION_INSCRIPCION:
            dispatch(removeSelectedOfferedSubject(socketMsg.dataJson.cursoId));
            dispatch(setLoadingRequestInTransit(false));
            break;
          case websocketTypes.RESPUESTA_INSCRIPCION_ORACLE:
            dispatch(removeSubProcessSectionIds(socketMsg.dataJson.cursoId));
            break;
          default:
            break;
        }

        dispatch(clearUnsubProcessSectionIds([]));
      }
      /* ----------------------------- IF socket is OK ---------------------------- */
      if (socketMsg.code === 200 && socketMsg.type) {
        const idPersona = socketMsg.dataJson.personaId;

        if (
          idPersona === student.id &&
          socketMsg?.message?.length > 0 &&
          socketMsg.type !== websocketTypes.WARNING_MENSAJE
        ) {
          showSuccess(socketMsg.message);
        }
        switch (socketMsg.type) {
          case websocketTypes.LOGOUT_GENERAL:
            handleLogout();
            break;
          case websocketTypes.CAMBIO_PRIORIDAD_ALUMNOS:
            handleCambioPrioridadAlumnos(socketMsg);
            break;
          case websocketTypes.WARNING_MENSAJE:
            showWarningMessage(socketMsg.message);
            break;
          case websocketTypes.CAMBIO_PRIORIDAD:
            handleCambioPrioridad();
            break;
          case websocketTypes.CAMBIOS_EN_OFERTA:
            const idAlumnoPrograma = localStorage.getItem("idAP");
            console.log("Chequeo si socket cambio oferta me corresponde...");
            if (idAlumnoPrograma && socketMsg.dataJson.alumnosProgramasIds?.includes(Number(idAlumnoPrograma))) {
              console.log("✅ Efectivamente el socket me incluye!");
              if (ofertaEnCurso) {
                console.log("--> ⛔ CAMBIO OFERTA Ignorado");
                setCambiosIgnoradoContados(cambiosIgnoradoContados + 1);
              } else {
                console.log("--> 🆗 CAMBIO OFERTA No ignorado");
                setOfertaEnCurso(true);
                handleRegenerarOferta(socketMsg);
              }
            }
            break;
          case websocketTypes.CUPOS_RELOAD:
            handleCuposReload();
            break;
          case websocketTypes.ACTUALIZACION_OFERTA_KV:
            setOfertaEnCurso(false);
            if (cambiosIgnoradoContados > 0) {
              console.log("--> 🏁 TOTAL --> CAMBIOS EN OFERTA IGNORADOS: ", cambiosIgnoradoContados);
              setCambiosIgnoradoContados(0);
            } else {
              console.log("--> 🏁 TOTAL --> Sin cambios ignorados.");
            }
            handleRegenerarOferteEInscripciones(true, socketMsg);
            break;
          case websocketTypes.ACTUALIZACION_INSCRIPCION_KV:
            handleRegenerarOferteEInscripciones(false, socketMsg);
            break;
          default:
            break;
        }

        const handleGetCuposCurso = async (message: string) => {
          const { dataJson } = JSON.parse(message);
          const sectionId = dataJson.cursoId;

          if (dataJson && dataJson.cupoId) {
            const quotaId = dataJson.cupoId;
            const cuotaExist = subjectsQuotas && checkIfQuotaExist(subjectsQuotas, sectionId, quotaId);
            if (cuotaExist) {
              await dispatch(updateCupoCurso(quotaId));
            }
          }
        };
        handleGetCuposCurso(lastMessage.data);

        if (socketMsg.dataJson && socketMsg.type === websocketTypes.RESPUESTA_INSCRIPCION_ORACLE) {
          console.log(subcriptionProcessSectionIds);
          dispatch(removeSubProcessSectionIds(socketMsg.dataJson.cursoId));
        }
        if (socketMsg.dataJson && socketMsg.type === websocketTypes.RESPUESTA_DESINSCRIPCION_ORACLE) {
          dispatch(deleteUnsubProcessSectionId(socketMsg.dataJson.cursoId));
        }

        if (socketMsg.dataJson && socketMsg.type === websocketTypes.RESERVA_SECCION_MULTIPLE) {
          console.error("MULTIPLE reserva ---", socketMsg);
        }
      }
    }
  }, [lastMessage]);

  /* -------------------------------------------------------------------------- */
  /*                                 HEART BEAT                                 */
  /* -------------------------------------------------------------------------- */
  // Sends a periodical heartbeat message through the websocket connection.
  useEffect(() => {
    // TODO: revisar el heartbeat y multiples renders
    if (readyState === 1) {
      heartbeatIntervalRef.current = window.setInterval(() => {
        if (socketHeartBeatUrl) {
          const lastHeartbeat = previousHeartbeats[socketHeartBeatUrl];
          const deltaFromNow = (Date.now() - lastHeartbeat) / 1000;
          // Send a heartbeat message if it hasn't already been sent within the last 10 seconds.
          if (!lastHeartbeat || deltaFromNow > 5) {
            // Send the heartbeat message and update the heartbeat history.
            // console.log("Sending heart beat...");
            sendMessage("{ping}");
            previousHeartbeats[socketHeartBeatUrl] = Date.now();
          }
        }
        // }, 10000);
      }, 5000);
    }

    return () => {
      clearInterval(heartbeatIntervalRef.current);
    };
  }, [socketHeartBeatUrl, readyState, sendMessage]);

  return <></>;
}
