import { useState, KeyboardEvent, useEffect } from "react";
import "./Login.css";
import logo from "../../assets/ditella-logo.png";
import SlInput from "@shoelace-style/shoelace/dist/react/input";
import { useNavigate } from "react-router-dom";
import { login, postSeleccionaCarrera } from "../../Api/authentication";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../auth/authConfig";
import { useDispatch, useSelector } from "react-redux";
import {
  selectParameter,
  setServerError,
  setUnknownUser,
  updateParametersData,
} from "../../reduxSlices/parametersSlice";
import { checkRole } from "../../Api/role";
import { selectAdmin, updateAdminData } from "../../reduxSlices/adminSlice";
import { AppState } from "../../enums/appState.enum";
import {
  selectStudent,
  setCarrearActual,
  setListadoCarreras,
  setStudentBloqueo,
  setStudentInicializado,
  setStudentMostrarHorarios,
  setStudentPrioridad,
  setToken,
} from "../../reduxSlices/studentSlice";
import { useToastError } from "../../hooks/useToast";
import { EstadoLoginStudent } from "../../types";
import SlSpinner from "@shoelace-style/shoelace/dist/react/spinner";

const Login = () => {
  const [email, setEmail] = useState("");
  const [logingIn, setLogingIn] = useState(false);
  const { instance, accounts } = useMsal();
  const { appState } = useSelector(selectParameter);
  const { role } = useSelector(selectAdmin);
  const student = useSelector(selectStudent);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showError = useToastError();
  const [multicarrera, setMulticarrera] = useState(false);
  const [carrerasList, setCarrerasList] = useState<any[]>([]);
  const [selectingCarrera, setSelectingCarrera] = useState(false);

  const token = localStorage.getItem("token");

  if(!token){
    localStorage.clear();
  }
  if (token && !multicarrera) {
    dispatch(setStudentInicializado(0));
    navigate("/");
  } else {
    dispatch(setStudentInicializado(2));
  }

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  function MicrosoftLogin() {
    const loginMS = async () => {
      try {
        await instance.loginRedirect(loginRequest);
      } catch (error) {
        console.error(error);
      }
    };
    loginMS();
  }

  /* -------------------------------------------------------------------------- */
  /*                                MONTADO Login                               */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (student.id && student.studentInitState && token) {
      navigate("/");
    }
  }, [student]);

  async function ingresarEnApp(token: string, mostrarHorarios: boolean, inicializando: boolean, idAP?: number) {
    localStorage.setItem("token", token);
    dispatch(setToken(token));
    if (idAP) localStorage.setItem("idAP", JSON.stringify(idAP));
    if (mostrarHorarios) dispatch(setStudentMostrarHorarios(mostrarHorarios));

    /* ------------------------------------ . ----------------------------------- */
    if (inicializando) {
      await dispatch(setStudentInicializado(1));
    } else {
      await dispatch(setStudentInicializado(2));
    }
    navigate("/");
  }

  async function seleccionaCarrera(idAlu: number, nombreCarrera: string) {
    const res: EstadoLoginStudent = await postSeleccionaCarrera(idAlu);
    const { token, inicializando, mostrarHorarios } = res;

    dispatch(setCarrearActual(nombreCarrera));

    ingresarEnApp(token, mostrarHorarios, inicializando, idAlu);
    setSelectingCarrera(false);
  }

  const handleLoginGetToken = async (email: string) => {
    setLogingIn(true);
    try {
      const res: EstadoLoginStudent = await login(email);
      const {
        token,
        inicializando,
        mensajePrioridad,
        prioridad,
        mostrarHorarios,
        debeElegirCarrera,
        carreras,
        cantidadCarreras,
        bloqueado,
        mensajeBloqueo,
        contactoBloqueo,
      } = res || {};

      //@ts-ignore
      if (res?.error?.response?.status === 401) {
        showError("Email incorrecto o no permitido.");
      }

      // TODO: check error from server number
      //@ts-ignore
      if (res?.error?.response?.status === 404) {
        dispatch(setUnknownUser(true));
        navigate("/usuario");
      }

      // TODO: check error from server number
      //@ts-ignore
      if (res?.error?.response?.status >= 500) {
        dispatch(setServerError(true));
        navigate("/error");
      }

      //@ts-ignore
      if (res?.error) {
        setLogingIn(false);
        return;
      }

      // si esta inicialiazando solo me llega:
      // - bloqueado
      // - contactoBloqueo
      // - mensajeBloqueo
      // - cantidadCarreras
      // - incializando: true
      // - token "asdfsfsgdff"

      // si esta inicializado y bloqueado me llega
      // - token "-"
      // - bloqueado
      // - contactoBloqueo
      // - mensajeBloqueo
      // - cantidadCarreras
      // - incializando: false
      // - prioridad
      // - mensajePrioridad
      // - mostrarHorarios
      // - carreras
      // - debeElegirCarrera

      // si esta inicializado y SIN bloqueo
      // - token "afdsfsdf"
      // - bloqueado
      // - contactoBloqueo
      // - mensajeBloqueo
      // - cantidadCarreras
      // - incializando: false
      // - prioridad
      // - mensajePrioridad
      // - mostrarHorarios
      // - carreras
      // - debeElegirCarrera

      if (debeElegirCarrera && cantidadCarreras > 1 && !inicializando) {
        localStorage.setItem("token", token);
        setCarrerasList(carreras);
        setMulticarrera(true);
      }

      if (token.length > 5 && cantidadCarreras < 2 && !inicializando) {
        let iAP = undefined;
        if (carreras?.length > 0) {
          iAP = carreras[0]?.idAlumnoPrograma;
          dispatch(setCarrearActual(carreras[0].descripcionCarrera));
          dispatch(setListadoCarreras(carreras));
        }
        ingresarEnApp(token, mostrarHorarios, inicializando, iAP);
      }

      if (token.length < 5 && bloqueado) {
        dispatch(setStudentBloqueo({ mensaje: mensajeBloqueo, subMensaje: contactoBloqueo, activo: bloqueado }));
        navigate("/bloqueo");
      }

      if (token.length > 5 && inicializando) {
        ingresarEnApp(token, mostrarHorarios, inicializando);
      }
    } catch (err) {
      console.error("Login failed:", err);
    }
  };

  useEffect(() => {
    const actualUser = accounts[0]?.username;

    if (actualUser && !multicarrera) {
      checkRole(actualUser).then((res) => {
        if (res === "admin") {
          dispatch(updateAdminData({ name: actualUser, role: res }));
          // IF admin, show headermode
          dispatch(updateParametersData({ appState: AppState.BY_PASS, started: true }));
        } else {
          handleLoginGetToken(actualUser);
        }
      });
    }
  }, [accounts]);

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */

  const handleKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleInput = (e: any) => {
    if (e?.target?.value) {
      setEmail(e?.target?.value);
    }
  };

  const handleSelectCarrera = (id: number, nombreCarrera: string) => {
    setSelectingCarrera(true);
    seleccionaCarrera(id, nombreCarrera);
  };

  const colorsCarreras = ["#C47DDD", "#2277DA", "#209DAE", "#97D86C", "#FBC54F", "#FF7A30", "#FF7A30"];

  return (
    <>
      <div className="page">
        <img src={logo} alt="logo Ditella" width={150} />
        {appState === "0" && role === "user" && accounts[0]?.username && !multicarrera && (
          <SlSpinner
            style={{
              fontSize: "3rem",
              //@ts-ignore
              "--indicator-color": "#00C4B3",
              "--track-color": "#ccebe8",
            }}
          />
        )}
        {appState === "0" && role === "user" && !accounts[0]?.username && !multicarrera && (
          <>
            <p>Para realizar la inscripción deberá ingresar al sistema utilizando su mail de la universidad.</p>
            <p>Identifíquese usando su cuenta.</p>

            <div className="row">
              <SlButton className="full-width" size="medium" type="button" onClick={MicrosoftLogin}>
                <SlIcon slot="prefix" name="box-arrow-in-right"></SlIcon> Microsoft login
              </SlButton>
            </div>
          </>
        )}
        {(appState === "2" || role === "admin") && !multicarrera && (
          <form onKeyDown={handleKeyDown}>
            <div className="row">
              <SlInput className="input-test" name="email" value={email} onSlInput={handleInput} required />
              <SlButton
                size="medium"
                type="button"
                onClick={() => handleLoginGetToken(email)}
                disabled={logingIn}
                loading={logingIn}
              >
                <SlIcon slot="prefix" name="box-arrow-in-right"></SlIcon>
              </SlButton>
            </div>
          </form>
        )}
        {multicarrera && (
          <div className="container-multi-carrera">
            <p>Elegí tu carrera para poder ver tus materias.</p>
            {carrerasList.map((c, i) => (
              <button
                key={c.idAlumnoPrograma}
                className="carrera-container-select"
                disabled={selectingCarrera}
                style={{ borderColor: colorsCarreras[i] }}
                onClick={() => handleSelectCarrera(c.idAlumnoPrograma, c.descripcionCarrera)}
              >
                <h3>{c.descripcionCarrera}</h3>
              </button>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Login;
