import { useSelector } from "react-redux";
import "./FlexCalendar.css";
import {
  removeAllCalendarSubject,
  removeCalendarSubject,
  selectCalendarSubjectsList,
  selectInscribedSubjects,
  selectOfferedSubjects,
  setDetailSubjectOffered,
} from "../../reduxSlices/subjectsSlice";
import { useEffect, useRef, useState } from "react";
import { ICalendarizedSubjects, ICalendarSubjects, IInscribedSubject, IOfferedSubjectSection } from "../../types";
import { asignacionPropiedadesParseoCursos, assignColorToSubject } from "../../utils/calendarHelpers";
import EmptyCalendar from "../EmptyCalendar/EmptyCalendar";
import { useAppDispatch } from "../../app/store";
import {
  selectFlags,
  selectMobileFeatures,
  setLastCalendarAdd,
  setPanelFilters,
} from "../../reduxSlices/globalFlagsSlice";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { useToastWarning } from "../../hooks/useToast";

function calcularDuracionEnMinutos(horaInicio: number, horaFin: number): number {
  // Obtener las horas y los minutos de la hora de inicio
  const horasInicio = Math.floor(horaInicio / 100);
  const minutosInicio = horaInicio % 100;

  // Obtener las horas y los minutos de la hora de fin
  const horasFin = Math.floor(horaFin / 100);
  const minutosFin = horaFin % 100;

  // Convertir todo a minutos
  const inicioEnMinutos = horasInicio * 60 + minutosInicio;
  const finEnMinutos = horasFin * 60 + minutosFin;

  // Calcular la diferencia
  const duracionEnMinutos = finEnMinutos - inicioEnMinutos;
  return duracionEnMinutos;
}
const getWidthPercentage = (curso: ICalendarSubjects): string => {
  if (curso.superpuesta === 0) {
    return "100%";
  } else if (curso.superpuesta === 1) {
    return "75%";
  } else if (curso.superpuesta === 2) {
    return "50%";
  } else {
    return "25%";
  }
};
function calcularAlturaEnPixeles(inicio: number, horaFormato: number): number {
  const horaInicio = inicio; // Hora de referencia que equivale a 0 píxeles (8:00 AM)
  const alturaPorHora = 60; // Altura en píxeles por cada hora

  // Extraer horas y minutos del formato numérico
  const hora = Math.floor(horaFormato / 100);
  const minutos = horaFormato % 100;

  // Calcular la altura en píxeles
  const altura = (hora - horaInicio) * alturaPorHora + (minutos / 60) * alturaPorHora;

  return altura;
}
const formatearSeccionNombreTarjeta = (text: string) => {
  if (text.includes(" (")) {
    const parts = text.split(" (");
    const result = parts[0].trim();
    return result.replace(" - S", ". S");
  } else {
    return text;
  }
};

export default function FlexCalendar() {
  const calendarSubjectsList = useSelector(selectCalendarSubjectsList);
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const offeredSubjects = useSelector(selectOfferedSubjects);
  const mobileFeatures = useSelector(selectMobileFeatures);
  const { lastCalendarAdd } = useSelector(selectFlags);
  const { lastOfertaUpdateTime } = useSelector(selectFlags);
  const dispatch = useAppDispatch();
  const showWarning = useToastWarning();

  const [calendarSubjects, setCalendarSubjects] = useState<ICalendarSubjects[]>([]);
  const [minHour, setMinHour] = useState(0);
  const [maxHour, setMaxHour] = useState(0);
  const [saturday, setSaturday] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [eventAdedd, setEvenAdedd] = useState(false);

  const [dayView, setDayView] = useState(0);

  const franjasHorarias = [
    {
      hora: 8,
      modulo: "8:00h",
      minutos: 800,
    },
    {
      hora: 9,
      modulo: "9:45h",
      minutos: 945,
    },
    {
      hora: 11,
      modulo: "11:30h",
      minutos: 1130,
    },
    {
      hora: 13,
      modulo: "13:45h",
      minutos: 1345,
    },
    {
      hora: 15,
      modulo: "15:30h",
      minutos: 1530,
    },
    {
      hora: 17,
      modulo: "17:15h",
      minutos: 1715,
    },
    {
      hora: 19,
      modulo: "19:00h",
      minutos: 1900,
    },
    {
      hora: 20,
      modulo: "20:45h",
      minutos: 2045,
    },
    {
      hora: 23,
      modulo: "23:00h",
      minutos: 2300,
    },
  ];

  const filterRepeatCalendarSubs = (calendarSubs: ICalendarSubjects[]) => {
    // filtro horario duplicado por materia y curso
    const sinDuplicar = calendarSubs.filter(
      (cs: any, index: any, self: any[]) =>
        index ===
        self.findIndex(
          (c) =>
            c.idMateria === cs.idMateria &&
            c.dia === cs.dia &&
            c.desde === cs.desde &&
            c.hasta === cs.hasta &&
            c.idCurso === cs.idCurso
        )
    );
    return sinDuplicar;
  };

  /* -------------------------------------------------------------------------- */
  /*                                   EFFECTS                                  */
  /* -------------------------------------------------------------------------- */

  const updateDimensions = () => {
    if (divRef.current) {
      const { clientWidth, clientHeight } = divRef.current;
      setDimensions({
        width: clientWidth,
        height: clientHeight,
      });
    }
  };

  useEffect(() => {
    // Dimensiones para posicionar el btn Clean
    updateDimensions();

    const divElement = divRef.current;
    if (!eventAdedd) {
      window.addEventListener("resize", updateDimensions);
      if (divElement) {
        divElement.addEventListener("scroll", updateDimensions);
      }
      setEvenAdedd(true);
    }

    /* ------------------ scroll hasta el ultimo calendarizado ------------------ */
    setTimeout(() => {
      if (lastCalendarAdd) {
        const idSelector = "#curso-card-" + lastCalendarAdd;
        const scrollTarget = document.querySelector(idSelector);
        if (scrollTarget) scrollTarget.scrollIntoView({ behavior: "smooth", block: "center" });
        dispatch(setLastCalendarAdd(""));
      }
    }, 1000);

    return () => {
      window.removeEventListener("resize", updateDimensions);
      if (divElement) {
        divElement.removeEventListener("scroll", updateDimensions);
      }
    };
  }, [calendarSubjects]);

  useEffect(() => {
    const toCalendarSubjects = () => {
      const turnos: any = [];
      if (inscribedSubjects && inscribedSubjects.length > 0) {
        inscribedSubjects.forEach((subject) => {
          const title = subject.title;
          const materia = offeredSubjects?.find((materia) => Number(materia.id) === subject.id);
          const curso = materia?.sections.find((curso) => curso.id === subject.idCurso);
          // recorrer todos los subject info!
          // const horarios = curso?.subjectData.subjectsByType[0].subjectInfo;
          const horarios = curso?.subjectData.subjectsByType.map((subInfo: any) => subInfo.subjectInfo).flat();
          // console.log(horarios);

          horarios?.forEach((horario) => {
            turnos.push({
              idMateria: materia?.id,
              idCurso: curso?.id,
              dia: horario?.values.dia,
              desde: Number(horario?.values.desde),
              hasta: Number(horario?.values.hasta),
              profesor: horario?.values.profesores[0],
              curso: title,
              colors: assignColorToSubject(Number(curso?.id)),
              warning: false,
              realInscribed: true,
              superpuesta: 0,
              tipoCursada: horario?.values.tipoClase,
            });
          });
        });
      }

      const turnosCalendarizados: any = [];
      if (calendarSubjectsList.length > 0) {
        calendarSubjectsList.forEach((subject: ICalendarizedSubjects) => {
          const materia = offeredSubjects?.filter((materia) => Number(materia.id) === Number(subject.idMateria))[0];
          const curso = materia?.sections.find((section: any) => Number(section.id) === Number(subject.idCurso));
          const horarios = curso?.subjectData.subjectsByType.map((subInfo: any) => subInfo.subjectInfo).flat();

          horarios?.forEach((horario) => {
            turnosCalendarizados.push({
              idMateria: subject.idMateria,
              idCurso: curso?.id,
              dia: horario?.values.dia,
              desde: Number(horario?.values.desde),
              hasta: Number(horario?.values.hasta),
              profesor: horario?.values.profesores[0],
              curso: materia?.title,
              colors: assignColorToSubject(Number(curso?.id)),
              warning: false,
              realInscribed: false,
              superpuesta: 0,
              tipoCursada: horario?.values.tipoClase,
            });
          });
        });
      }
      return [...turnos, ...turnosCalendarizados];
    };

    const calendarSubs = toCalendarSubjects();
    const calendarSubsWithoutRep = filterRepeatCalendarSubs(calendarSubs);
    /* --------------------- Setting materias calendar --------------------- */
    const copyList = JSON.stringify(calendarSubsWithoutRep);
    const cursosParseados = asignacionPropiedadesParseoCursos(JSON.parse(copyList));
    setCalendarSubjects(cursosParseados);
    console.log("Componente: CALENDARIO -> Actualizado 🔄");
  }, [inscribedSubjects, calendarSubjectsList, lastOfertaUpdateTime]);

  useEffect(() => {
    /* ---------------------- alert supersposicion excesiva --------------------- */
    if (calendarSubjects.find((curso) => curso.superpuesta > 2)) {
      showWarning("Múltipes horarios superpuestos, posible incompatibilidad.");
    }
    // setMinHour(calcularHoraMasTemprana(calendarSubjects));
    // setMaxHour(calcularHoraMasTarde(calendarSubjects));
    setMinHour(8);
    setMaxHour(22);

    const earlySubjects = calendarSubjects.filter((sub) => sub.desde < 1700);
    if (divRef.current && calendarSubjects.length && earlySubjects.length === 0) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }

    const saturdayFilter = calendarSubjects.filter((curso) => curso.dia === "Sábado");
    if (saturdayFilter.length > 0) {
      setSaturday(true);
    } else {
      setSaturday(false);
    }
  }, [calendarSubjects]);

  useEffect(() => {
    const removeFromCalendarizeIfInscribed = (inscriptas: IInscribedSubject[], calendarizadas: ICalendarSubjects[]) => {
      const idsInscriptas = inscriptas.map((s) => s.idCurso);
      idsInscriptas.forEach((id) => {
        const existe = calendarizadas.some((item) => item.idCurso === id);
        if (existe) {
          dispatch(removeCalendarSubject(id));
        }
      });
    };
    if (inscribedSubjects && inscribedSubjects?.length > 0) {
      removeFromCalendarizeIfInscribed(inscribedSubjects, calendarSubjects);
    }
  }, [inscribedSubjects]);

  /* -------------------------------------------------------------------------- */
  /*                              CALENDARIO VACIO                              */
  /* -------------------------------------------------------------------------- */
  if (calendarSubjectsList.length === 0 && (inscribedSubjects === null || inscribedSubjects.length === 0)) {
    return <EmptyCalendar />;
  }

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */
  const handleClearCalendarize = () => {
    dispatch(removeAllCalendarSubject());
  };

  const handleShowSubjectoInfo = (subjectId: number, cursoId: number) => {
    dispatch(setPanelFilters(false));
    dispatch(setDetailSubjectOffered({ subjectId, cursoId }));
  };

  const handleSelectDay = (day: number) => {
    if (mobileFeatures) {
      setDayView(day);
    }
    return;
  };

  const handleSetIndividualViewDay = () => {
    if (dayView) {
      setDayView(0);
    } else {
      setDayView(1);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                SUBCOMPONENTS                               */
  /* -------------------------------------------------------------------------- */
  const renderCalendarSubjects = (subjects: ICalendarSubjects[]) => {
    return subjects.map((m) => {
      return (
        <div
          onClick={() => handleShowSubjectoInfo(m.idMateria, m.idCurso)}
          key={"weekly" + m.idMateria + m.dia + m.desde + m.profesor + m.idCurso}
          id={String("curso-card-" + m.idCurso)}
          className={`flexcalendar-subject-card ${m.realInscribed ? "flexcalendar-subject-card-confirmed" : ""}`}
          style={{
            borderLeftWidth: dayView !== 0 ? "25px" : "",
            borderRightWidth: dayView !== 0 ? "6px" : "",
            borderBottomWidth: dayView !== 0 ? "3px" : "",
            height: `${calcularDuracionEnMinutos(m.desde, m.hasta) - 4}px`, //-4 para compensar que el alto no sea mayor
            top: `${calcularAlturaEnPixeles(minHour, m.desde) + 4}px`, //+4 para dar margen con la linea horaria
            width: getWidthPercentage(m),
            borderColor: m.color,
            backgroundColor: m.realInscribed ? m.color : "white",
            cursor: "pointer",
            zIndex: m.superpuesta,
          }}
        >
          <h5
            title={formatearSeccionNombreTarjeta(m.curso)}
            style={{ color: `${m.realInscribed ? "white" : m.color}` }}
          >
            {formatearSeccionNombreTarjeta(m.curso)}
          </h5>
          {m.tipoCursada && (
            <p
              style={m.realInscribed ? { marginTop: "auto", color: "white" } : { marginTop: "auto", color: "#424242" }}
            >
              {m.tipoCursada}
            </p>
          )}
          {m.warning && (
            <SlIcon title="Superposicion horaria" name="exclamation-lg" className="flexcalendar-warn-icon"></SlIcon>
          )}
        </div>
      );
    });
  };

  return (
    <>
      <div className="flexcalendar-dias">
        <h4
          onClick={() => {
            handleSelectDay(1);
          }}
          style={{ color: dayView === 1 ? "#00C4B3" : "" }}
        >
          {mobileFeatures ? "Lun" : "Lunes"}
        </h4>
        <h4
          onClick={() => {
            handleSelectDay(2);
          }}
          style={{ color: dayView === 2 ? "#00C4B3" : "" }}
        >
          {mobileFeatures ? "Mar" : "Martes"}
        </h4>
        <h4
          onClick={() => {
            handleSelectDay(3);
          }}
          style={{ color: dayView === 3 ? "#00C4B3" : "" }}
        >
          {mobileFeatures ? "Miér" : "Miércoles"}
        </h4>
        <h4
          onClick={() => {
            handleSelectDay(4);
          }}
          style={{ color: dayView === 4 ? "#00C4B3" : "" }}
        >
          {mobileFeatures ? "Jue" : "Jueves"}
        </h4>
        <h4
          onClick={() => {
            handleSelectDay(5);
          }}
          style={{ color: dayView === 5 ? "#00C4B3" : "" }}
        >
          {mobileFeatures ? "Vie" : "Viernes"}
        </h4>
        {saturday && (
          <h4
            onClick={() => {
              handleSelectDay(6);
            }}
            style={{ color: dayView === 6 ? "#00C4B3" : "" }}
          >
            {mobileFeatures ? "Sáb" : "Sábado"}
          </h4>
        )}
      </div>
      <div className="flexcalendar-container" id="flexcalendar" ref={divRef}>
        <div className="flexcalendar-horarios">
          {franjasHorarias.map((item, index) => {
            return (
              <div
                key={item.modulo}
                className="flexcalendar-horarios-row"
                style={{
                  width: "100%",
                  height: index === franjasHorarias.length - 1 ? "100px" : "60px",
                  position: "absolute",
                  top: `${calcularDuracionEnMinutos(minHour * 100, item.minutos)}px`,
                }}
              >
                <p className="flexcalendar-horarios-row-title">{item.modulo}</p>
              </div>
            );
          })}
        </div>

        {mobileFeatures && dayView !== 0 ? (
          <div className="flexcalendar-cursadas">
            {dayView === 1 && (
              <>
                <div className="flexcalendar-cursadas-col">
                  {/* Lunes */}
                  {renderCalendarSubjects(calendarSubjects.filter((item) => item.dia === "Lunes"))}
                </div>
              </>
            )}
            {dayView === 2 && (
              <>
                <div className="flexcalendar-cursadas-col">
                  {/* Martes */}
                  {renderCalendarSubjects(calendarSubjects.filter((item) => item.dia === "Martes"))}
                </div>
              </>
            )}
            {dayView === 3 && (
              <>
                <div className="flexcalendar-cursadas-col">
                  {/* Miercoles */}
                  {renderCalendarSubjects(calendarSubjects.filter((item) => item.dia === "Miércoles"))}
                </div>
              </>
            )}
            {dayView === 4 && (
              <>
                <div className="flexcalendar-cursadas-col">
                  {/* Jueves */}
                  {renderCalendarSubjects(calendarSubjects.filter((item) => item.dia === "Jueves"))}
                </div>
              </>
            )}
            {dayView === 5 && (
              <>
                <div className="flexcalendar-cursadas-col">
                  {/* Viernes */}
                  {renderCalendarSubjects(calendarSubjects.filter((item) => item.dia === "Viernes"))}
                </div>
              </>
            )}
            {dayView === 6 && saturday && (
              <>
                <div className="flexcalendar-cursadas-col">
                  {/* Sábado */}
                  {renderCalendarSubjects(calendarSubjects.filter((item) => item.dia === "Sábado"))}
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="flexcalendar-cursadas">
            <div className="flexcalendar-cursadas-col">
              {/* Lunes */}
              {renderCalendarSubjects(calendarSubjects.filter((item) => item.dia === "Lunes"))}
            </div>
            <div className="flexcalendar-col-divider"></div>
            <div className="flexcalendar-cursadas-col">
              {/* Martes */}
              {renderCalendarSubjects(calendarSubjects.filter((item) => item.dia === "Martes"))}
            </div>
            <div className="flexcalendar-col-divider"></div>
            <div className="flexcalendar-cursadas-col">
              {/* Miercoles */}
              {renderCalendarSubjects(calendarSubjects.filter((item) => item.dia === "Miércoles"))}
            </div>
            <div className="flexcalendar-col-divider"></div>
            <div className="flexcalendar-cursadas-col">
              {/* Jueves */}
              {renderCalendarSubjects(calendarSubjects.filter((item) => item.dia === "Jueves"))}
            </div>
            <div className="flexcalendar-col-divider"></div>
            <div className="flexcalendar-cursadas-col">
              {/* Viernes */}
              {renderCalendarSubjects(calendarSubjects.filter((item) => item.dia === "Viernes"))}
            </div>
            {saturday && (
              <>
                <div className="flexcalendar-col-divider"></div>
                <div className="flexcalendar-cursadas-col">
                  {/* Sábado */}
                  {renderCalendarSubjects(calendarSubjects.filter((item) => item.dia === "Sábado"))}
                </div>
              </>
            )}
          </div>
        )}

        {calendarSubjectsList.length > 0 && (
          <button
            title="Resetear calendario"
            className="flexcalendar-btn-clear"
            onClick={handleClearCalendarize}
            style={
              mobileFeatures
                ? { position: "fixed", bottom: "85px", left: dimensions.width - 3 }
                : { top: dimensions.height - 35, left: dimensions.width - 3, marginRight: "5px", zIndex: 19 }
            }
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path
                d="M17.875 13.75H13.75V12.375H17.875V13.75ZM20.625 19.25H16.5V17.875H20.625V19.25ZM19.25 16.5H15.125V15.125H19.25V16.5Z"
                fill="#00C4B3"
              />
              <path
                d="M11.6896 13.75C11.6844 13.17 11.5295 12.6011 11.24 12.0985C10.9504 11.5959 10.536 11.1765 10.0368 10.8811L15.125 2.0625L13.9356 1.375L8.72646 10.3991C8.08715 10.2564 7.42225 10.2762 6.79256 10.4567C6.16287 10.6372 5.58848 10.9727 5.12189 11.4324C2.54789 13.915 2.74727 19.7189 2.75689 19.965C2.764 20.1425 2.83956 20.3104 2.96773 20.4335C3.0959 20.5565 3.26672 20.6251 3.44439 20.625H13.7507C13.895 20.625 14.0356 20.5796 14.1527 20.4952C14.2698 20.4109 14.3573 20.2918 14.4029 20.1549C14.4486 20.018 14.45 19.8702 14.4069 19.7325C14.3639 19.5948 14.2786 19.4741 14.1632 19.3875C11.7295 17.5615 11.6896 13.7871 11.6896 13.75ZM8.2019 11.6854C8.75215 11.6915 9.27889 11.9095 9.67252 12.294C10.0662 12.6785 10.2964 13.2 10.3153 13.75C10.3153 13.7761 10.3166 13.893 10.327 14.0724L6.27071 12.2684C6.5379 12.0481 6.84622 11.8831 7.17776 11.783C7.5093 11.6829 7.85742 11.6498 8.2019 11.6854ZM10.6219 19.25C10.0724 18.6905 9.72209 17.9657 9.62502 17.1875H8.25002C8.29926 17.9192 8.52781 18.6275 8.91552 19.25H7.38721C7.10436 18.3578 6.93234 17.4342 6.87502 16.5H5.50002C5.54409 17.4314 5.69754 18.3544 5.95721 19.25H4.12502C4.14633 17.9878 4.32439 15.1993 5.36458 13.3698L10.5435 15.6729C10.7918 16.9651 11.3224 18.1866 12.0973 19.25H10.6219Z"
                fill="#00C4B3"
              />
            </svg>
          </button>
        )}
        {mobileFeatures && (
          <button
            onClick={handleSetIndividualViewDay}
            title="Alternar vista diaria"
            className="flexcalendar-btn-individual-day"
            style={{
              position: "fixed",
              bottom: "85px",
              left: 20,
              backgroundColor: dayView === 0 ? "white" : "#00C4B3",
            }}
          >
            <SlIcon style={{ color: dayView === 0 ? "#00C4B3" : "white" }} name="calendar4-week"></SlIcon>
          </button>
        )}
      </div>
    </>
  );
}
