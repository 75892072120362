import { useSelector } from "react-redux";
import { selectStudent, setStudentPrioridad } from "../../reduxSlices/studentSlice";
import { useState } from "react";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { updatePrioridad } from "../../Api/prioridad";
import { useAppDispatch } from "../../app/store";
import { useToastSuccess, useToastWarning } from "../../hooks/useToast";
import { setBannerPrioridadVisible } from "../../reduxSlices/globalFlagsSlice";

export default function BannerConsultaPrioridad() {
  const dispatch = useAppDispatch();
  const showWarning = useToastWarning();
  const showSuccess = useToastSuccess();

  const { prioridad, mensajePrioridad } = useSelector(selectStudent);
  const [loading, setLoading] = useState(false);

  if (prioridad) {
    return null;
  }

  const handleBannerPrioridad = () => {
    setLoading(true);
    const updatePrioridadOnRefresh = async () => {
      const response = await updatePrioridad(prioridad);
      console.log(response);

      if (!response.tienePrioridad) {
        showWarning("Aún no comenzó tu turno de inscripción.");
      } else {
        showSuccess("Ya podés inscribirte.", "¡Excelente!");
      }
      dispatch(setBannerPrioridadVisible(true));

      await dispatch(
        setStudentPrioridad({ prioridad: response.tienePrioridad, mensajePrioridad: response.mensajePrioridad })
      );
      setLoading(false);
    };
    updatePrioridadOnRefresh();
  };

  return (
    <div
      className={`border-[1px] border-blue-400 bg-blue-100 rounded-md flex justify-between items-center flex-wrap gap-[15px] px-4 py-2 md:py-0 min-h-[48px] mb-2 md:mb-0`}
    >
      <div className="flex items-center gap-[15px] flex-grow">
        <span className="bg-blue-600 min-w-[26px] min-h-[26px] flex items-center justify-center rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" width="3" height="15" viewBox="0 0 3 15" fill="none">
            <path
              d="M1.74457 4.12639C2.36431 4.12639 2.8667 4.62879 2.8667 5.24852L2.8667 13.8112C2.8667 14.431 2.36431 14.9333 1.74457 14.9333V14.9333C1.12484 14.9333 0.622446 14.431 0.622446 13.8112L0.622446 5.24852C0.622447 4.62879 1.12484 4.12639 1.74457 4.12639V4.12639ZM1.74457 0.814363C2.35814 0.814363 2.8667 1.31175 2.8667 1.92532V1.92532C2.8667 2.53888 2.35814 3.03627 1.74457 3.03627V3.03627C1.13101 3.03627 0.622447 2.53888 0.622447 1.92532V1.92532C0.622447 1.31175 1.13101 0.814363 1.74457 0.814363V0.814363Z"
              fill="white"
            />
          </svg>
        </span>
        <p className="text-[13px] md:text-[14px] leading-none">
          Si ya comenzó tu turno de inscripción y no ves el botón “Inscribirme” actualizá la lista de cursos.
        </p>
      </div>

      <button
        className={` bg-blue-600 text-sm text-white flex justify-center items-center gap-2 p-2 px-4 rounded-lg font-bold disabled:cursor-not-allowed min-w-full md:min-w-[100px] ${
          loading ? "bg-blue-400" : ""
        }`}
        type="button"
        onClick={handleBannerPrioridad}
        disabled={loading}
      >
        <SlIcon slot="prefix" name="arrow-clockwise" className={`${loading ? "animate-spin" : ""}`}></SlIcon> Actualizar
      </button>
    </div>
  );
}
