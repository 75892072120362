import { useSelector } from "react-redux";
import { selectInscribedSubjects } from "../../reduxSlices/subjectsSlice";
import "./MobileMenu.css";
import { Link, useLocation } from "react-router-dom";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

const MobileMenu = () => {
  const location = useLocation();
  return (
    <div className="mobile-menu">
      <div className="mobile-wrapper-menu">
        <Link
          to="/calendario"
          className={`mobile-menu-link ${location.pathname === "/calendario" ? "mobile-menu-link-active" : ""}`}
        >
          <SlIcon name="calendar"></SlIcon>
          <span>Calendario</span>
        </Link>
        <div className="mobile-menu-divider"></div>
        <Link to="/" className={`mobile-menu-link ${location.pathname === "/" ? "mobile-menu-link-active" : ""}`}>
          <SlIcon name="card-list"></SlIcon>
          <span>Oferta de cursos</span>
        </Link>
      </div>
    </div>
  );
};

export default MobileMenu;
